import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/testimonal.css";

const Tesmonail = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Adjust the speed as needed
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoints as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Adjust the breakpoints as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="comment">
        <Container>
          <h2 className="main-heading">TESTIMONIALS</h2>
          <div className="your-component-wrapper">
            <Slider {...settings}>
              <div className="slide-dev">
                <p>
                  "DevCosmic is our go-to partner for app development in the
                  e-commerce sector. Their apps are a testament to their
                  expertise and attention to detail. The apps they developed for
                  us not only facilitate seamless transactions but also provide
                  an intuitive browsing experience. Their dedication to quality
                  and customer satisfaction is evident in every interaction and
                  deliverable. We highly recommend their services to anyone
                  seeking top-notch digital solutions."
                </p>
                <span>Shafqat Ahmad - CEO SmartVision</span>
              </div>
              <div className="slide-dev">
                <p>
                  "DevCosmic delivered exceptional results! Their team’s
                  dedication to understanding our needs and implementing
                  innovative solutions was impressive. We highly recommend
                  DevCosmic for their professionalism and expertise"
                </p>
                <span> David Thompson, CTO, Tech Innovations Ltd.</span>
              </div>
              <div className="slide-dev">
                <p>
                  "We couldn't be happier with the results achieved by
                  DevCosmic. Their attention to detail, responsiveness, and
                  expertise in developing our app were truly commendable. The
                  app they created for us has garnered positive feedback from
                  our users, contributing significantly to our success. Thank
                  you for a job well done!"
                </p>
                <span>Emily White, Product Manager, Stellar Apps Inc.</span>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Tesmonail;
