import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import OurProduct from "../components/OurProduct";
import ServicesBox from "../components/ServicesBox";
import ourservices from "../utils/Ourservices.json";
import ServicesBoxLeft from "../components/ServicesBoxLeft";
import image from "../Assets/3.webp";
import image2 from "../Assets/img.webp";
import image3 from "../Assets/img2.webp";
import OurWork from "../utils/OurWork.json";
import { useNavigate } from "react-router-dom";
const ServicesPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Banner
        title={"Explore Our Expertise"}
        desc={
          "At our core, we're dedicated to delivering exceptional quality and inventive solutions. Witness firsthand how our passion for digital transformation can elevate your business to new heights"
        }
        display={"none"}
      />
      <ServicesBox
        secondHeading={OurWork[0].title}
        desc={OurWork[0].desc}
        display={"none"}
        image={image}
      />
      <ServicesBoxLeft
        secondHeading={OurWork[1].title}
        desc={OurWork[1].desc}
        display={"none"}
        image={image3}
      />
      <ServicesBox
        secondHeading={OurWork[2].title}
        desc={OurWork[2].desc}
        display={"none"}
        image={image2}
      />
      <ServicesBoxLeft
        secondHeading={OurWork[3].title}
        desc={OurWork[3].desc}
        display={"none"}
        image={image3}
      />
      <OurProduct title={"What we Do?"} OurServices={ourservices} />

      <div className="footer-section">
        <h3>Sounds Great?! Let’s work together!</h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default ServicesPage;
