import React from "react";
import { Routes, Route, Router } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import AboutUsPage from "../Pages/AboutUsPage";
import ContactUsPage from "../Pages/ContactUsPage";
import ServicesPage from "../Pages/ServicesPage";
import PortfolioPage from "../Pages/PortfolioPage";
import OurTeamPage from "../Pages/OurTeamPage";

const AllRoute = () => {
  return (
    <>
      <Routes >
        <Route path="/*" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/protflio" element={<PortfolioPage />} />
        <Route path="/ourteam" element={<OurTeamPage />} />
      </Routes>
    </>
  );
};

export default AllRoute;
