import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import OurProduct from "../components/OurProduct";
import ServicesBox from "../components/ServicesBox";
import ourservices from "../utils/Ourservices.json";
import ServicesBoxLeft from "../components/ServicesBoxLeft";
import image from "../Assets/Gameworld.webp";
import image2 from "../Assets/wENB.webp";
import image3 from "../Assets/Takdeer.webp";
import image5 from "../Assets/Skh.webp";
import image4 from "../Assets/Untitled-1.webp";

import { useNavigate } from "react-router-dom";

const PortfolioPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Banner
        title={`Elevating Businesses: Collaborative Success Stories in Our Portfolio`}
        desc={
          "At DevCosmic, our pride lies in the collaborative success stories showcased in our portfolio. Each project reflects our commitment to excellence and our dedication to crafting solutions that drive business growth."
        }
        display={"none"}
        screen={"home"}
      />
      <ServicesBox
        secondHeading={"Game-Centric Platform"}
        desc={
          "This vibrant website hosts a myriad of games, offering users a diverse selection for both single-player and multiplayer experiences. Users can immerse themselves in gaming content while also enjoying live streams and recorded videos from various creators. Game developers and content creators alike engage audiences through live streaming, making the platform a hub for interactive entertainment."
        }
        image={image}
      />
      <ServicesBox
        title={"We are Solution Providers"}
        secondHeading={"Admin Panel - Versatile Management Hub"}
        desc={
          "Our admin panel serves as a multifaceted management hub, offering an array of features tailored for comprehensive and streamlined control across various operational aspects. Designed with flexibility and efficiency in mind, this versatile tool empowers administrators to oversee and manage multiple functions seamlessly. Key Features Include: User Management, Data Analytics and Reporting, Content Management, Task and Workflow Management. These solutions Streamline task assignments, progress tracking, and workflow management for enhanced productivity and collaboration."
        }
        image={image2}
      />
      <ServicesBoxLeft
        secondHeading={"Matrimonial Apps"}
        desc={
          "Our team actively contributed to the development and enhancement of pioneering matrimonial application. This project involved the integration of live chat functionality and innovative features resembling the popular Superlike option. Focused on delivering an interactive platform for individuals seeking life partners, the role encompassed the implementation of features to facilitate real-time communication and augment user engagement."
        }
        image={image3}
      />
      <ServicesBox
        title={"We are Solution Providers"}
        secondHeading={
          "Video Creation and Sharing Platform - Innovative Multimedia Hub"
        }
        desc={
          "This platform stands as a dynamic space, revolutionizing multimedia content creation and sharing experiences. Tailored for creators, it offers intuitive tools enabling the crafting of captivating videos. Emphasizing user engagement, it fosters a vibrant community through profiles, social interaction features, and personalized content feeds. It's a hub where creativity thrives and communities connect through immersive multimedia experiences."
        }
        image={image4}
      />
      <ServicesBoxLeft
        title={"We are Solution Providers"}
        secondHeading={"App for Personalized Astrological Insights"}
        desc={
          "Offering daily, weekly, and monthly horoscopes, this user-centric application provides comprehensive readings and predictions covering various life aspects such as love, career, finance, and wellness. Users can explore astrological compatibility, access tarot card readings for additional guidance, and delve into informative articles on astrology and celestial events. With a user-friendly interface and customizable reminders, the app aims to provide a seamless and enriching experience for users of all astrological knowledge levels, helping them navigate life's journey based on celestial influences."
        }
        image={image5}
      />
      {/* <ServicesBox
        title={"We are Solution Providers"}
        secondHeading={
          " Crafting Tailored Solutions for Your Unique Challenges"
        }
        desc={
          "At DevCosmic, we understand that every business is unique. That's why we go beyond generic solutions, offering personalized strategies and architectures that align with your specific needs. Our commitment is to provide comprehensive solutions, ensuring that every line of code serves the purpose of enhancing your business objectives. With DevCosmic, your digital transformation is in expert hands."
        }
        image={image6}
      /> */}
      {/* <OurProduct title={"What we Do?"} OurServices={ourservices} /> */}

      <div className="footer-section">
        <h3>Sounds Great?! Let’s work together!</h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default PortfolioPage;
