import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/ContactUs.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { serverTimestamp } from "firebase/firestore";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
import Button from "../common/Button";
import shape2 from "../Assets/Shape2.webp";
import { addDoc, collection } from "@firebase/firestore";
import { db } from "../firestore/firebaseConfig";
import Swal from "sweetalert2";

// .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,"Invalid password")
const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(/^(?!.*@[^,]*,)/)
    .required("Email Required"),
  username: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Username should be alphabet")
    .required("UserName Required"),
  message: Yup.string().required("Message Required"),
});
const ContactUsFrom = () => {
  const initialValues = {
    username: "",
    email: "",
    message: "",
  };
  const onSubmit = (values, { resetForm }) => {
    handleValues(values.username, values.email, values.message);
    setTimeout(function () {
      resetForm({ values: "" });
    }, 5000);
  };

  const handleValues = async (username, email, message) => {
    console.log(username, email, message);
    let data = {
      username,
      email,
      message,
      createdAt: serverTimestamp(),
    };
    const ref = collection(db, "ContactUsRequests");
    let resp = await addDoc(ref, data);
    console.warn("resp", resp);
    Swal.fire({
      title: "We Hear Your Call",
      text: "We will get back to you soon!",
      icon: "success",
    });
  };

  return (
    <>
      <section className="contactus">
        <Container>
          <div className="form">
            <div className="form-body">
              <h2>Say Hi!</h2>
              <p>We’d like to talk with you.</p>
              <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={onSubmit}
              >
                {({ errors }) => (
                  <Form>
                    <span className="lable-span">My name is</span>
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      className="contactus-input"
                      placeholder="Full Name"
                    />
                    <div className="errMsg">{errors.username}</div>

                    <span className="lable-span">My Email is</span>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="contactus-input"
                      placeholder="Email"
                    />
                    <div className="errMsg">{errors.email}</div>

                    <span className="lable-span">Message</span>
                    <Field
                      type="text"
                      id="message"
                      name="message"
                      className="contactus-input"
                      placeholder="Message"
                    />
                    <div className="errMsg">{errors.message}</div>
                    <button className="btn" type="submit">
                      Send Message
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="contact-info">
              <div className="contact-info-body">
                <h2>Contact Information </h2>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>{" "}
                <p>
                  <BsFillTelephoneFill /> <span>+92-331-7726262</span>
                </p>
                <p>
                  <MdEmail /> <span>info@devcosmic.com</span>
                </p>
                <p style={{ marginBottom: " 20px" }}>
                  <ImLocation /> <span>Islamabad, Pakistan </span>
                </p>
                {/* <div className="social-link">
                  <p>
                    <AiFillFacebook />
                  </p>
                  <p>
                    <AiOutlineTwitter />
                  </p>
                  <p>
                    <AiFillLinkedin />
                  </p>
                  <p>
                    <AiFillInstagram />
                  </p>
                </div> */}
              </div>
              <img src={shape2} alt="" />
              <div className="overlay"></div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUsFrom;
