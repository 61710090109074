import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../Assets/Rectangle 7.webp";
import img2 from "../Assets/Group 3.webp";
import img3 from "../Assets/desktop 1.webp";
import "../styles/LatestWork.css";

const LatestWork = (props) => {
  return (
    <>
      <section className="Card-grid-section">
        <Container>
          <h2 className="main-heading">{props.title}</h2>
          <Row xs={1} md={1} lg={3} className="card-grid">
            {props.json.map((item, index) => (
              <Col>
                <div
                  className={
                    index == 0 || index == 2 || index == 3
                      ? "lastcard"
                      : "lastcard-middle"
                  }
                >
                  <img
                    src={index === 0 ? img1 : index === 1 ? img2 : img3}
                    alt=""
                  />
                  <h2>{item.title}</h2>
                  <p>{item.desc}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LatestWork;
