import React from "react";
import "../styles/Banner.css";
import shape from "../Assets/Shape.webp";
import Button from "../common/Button";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Banner = (props) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/contactus");
  };
  return (
    <>
      <div
        className={
          props.screen === "home"
            ? "banner"
            : props.screen === "about"
            ? "about-banner"
            : "services-banner"
        }
      >
        <Container>
          <div className="banner-content">
            <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              {props.title}
            </h2>
            <p>{props.desc}</p>
            <div
              style={{
                maxWidth: "50%",
                margin: "0 auto",
                display: `${props.display}`,
              }}
            >
              <Button title={"Get Started"} clickFunction={handleNavigation} />
            </div>
          </div>
        </Container>
        <img src={shape} alt="shape" className="shape-img" />
        <div className="overlay"></div>
      </div>
    </>
  );
};

export default Banner;
