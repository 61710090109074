import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../styles/OurTeam.css";
import img1 from "../Assets/WhatsApp Image 2023-11-18 at 12.34.07 PM.webp";
import img2 from "../Assets/WhatsApp Image 2023-11-25 at 7.17.38 PM.webp";
import img3 from "../Assets/WhatsApp Image 2023-11-11 at 12.00.33_d8c03129-modified.webp";
import img4 from "../Assets/Syed-Asadullah-iasadullah-Instagram-profile.webp";
import { useNavigate } from "react-router-dom";

const OurTeam = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ourTeam">
        <Container>
          <Row
            style={{
              textAlign: "center",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Col
              sm={8}
              md={8}
              lg={4}
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <h2
                className="main-heading ourTeamHeading"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                Our Team
              </h2>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img1} fluid />
                <div className="team-desc">
                  <h4>CEO</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img2} fluid />
                <div className="team-desc">
                  <h4>Co-founder</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img3} fluid />
                <div className="team-desc">
                  <h4>CTO</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img4} fluid />
                <div className="team-desc">
                  <h4>Full Stack Developer</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              sm={8}
              md={8}
              lg={4}
              onClick={() => {
                navigate("/ourteam");
              }}
            >
              <h2
                className="main-heading ourTeamHeading"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                See more
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurTeam;
