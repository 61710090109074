import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import OurServices from "../components/OurServices";
import LatestWork from "../components/LatestWork";
import OurWork from "../utils/OurWork.json";
import img1 from "../Assets/AdobeStock_170801444 1.webp";
import img2 from "../Assets/AdobeStock_227421922 1 (1).webp";
import { useNavigate } from "react-router-dom";

const AboutUsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Banner
        title={
          "Crafting Tomorrow's Digital Landscape: Embrace Innovation with DevCosmic"
        }
        desc={
          "At DevCosmic, we are catalysts of digital innovation, dedicated to transforming ideas into dynamic, functional realities. With a steadfast focus on web and app development, we pride ourselves on being architects of cutting-edge solutions tailored to meet the evolving needs of businesses worldwide."
        }
        display={"none"}
        screen={"about"}
      />
      <AboutUs
        title={"who we are"}
        secondHeading={" Defining Excellence Through Innovation"}
        desc={
          "At DevCosmic, we embody a commitment to excellence and innovation in every facet of our operations. We're pioneers of transformative solutions. With an unwavering focus on understanding and addressing the unique needs of our clients, we stand as a beacon of reliability, creativity, and proficiency, guiding businesses towards an era of digital transformation and success."
        }
        display={"none"}
        img={img1}
      />
      <OurServices
        title={"what we do"}
        secondHeading={" Crafting Tailored Client Solutions"}
        desc={
          "At DevCosmic, we prioritize our clients' needs above all. We believe in creating unique solutions tailored just for them, without tying ourselves to specific technologies. Our goal is simple: help our clients move forward by providing flexible and varied solutions that cover all stages of their needs. This way, they always have access to the best and latest solutions available in the market."
        }
        display={"none"}
        img={img2}
      />
      <LatestWork title={"Our Work"} json={OurWork} />
      <div className="footer-section">
        <h3>Sounds Great?! Let’s work together!</h3>
        <span
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us
        </span>
      </div>
    </>
  );
};

export default AboutUsPage;
