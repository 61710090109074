import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../styles/OurTeam.css";
import img1 from "../Assets/WhatsApp Image 2023-11-18 at 12.34.07 PM.webp";
import img2 from "../Assets/WhatsApp Image 2023-11-25 at 7.17.38 PM.webp";
import img3 from "../Assets/WhatsApp Image 2023-11-11 at 12.00.33_d8c03129-modified.webp";
import img4 from "../Assets/Syed-Asadullah-iasadullah-Instagram-profile.webp";
import img5 from "../Assets/no-profile-picture-female-15290.png";
import img6 from "../Assets/zeeshan.png";
import img7 from "../Assets/WhatsApp Image 2023-11-26 at 5.47.46 PM.jpeg";
import Navbar from "../components/Navbar";

const OurTeamPage = () => {
  return (
    <>
      <Navbar />
      <section className="ourTeam">
        <Container>
          <Row
            style={{
              textAlign: "center",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Col
              sm={8}
              md={8}
              lg={4}
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <h2
                className="main-heading ourTeamHeading"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                Hello we are DevCosmic!!
              </h2>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img1} fluid />
                <div className="team-desc">
                  <h4>CEO</h4>
                  <p>Muhammad ALiyan</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img2} fluid />
                <div className="team-desc">
                  <h4>Co-founder</h4>
                  <p>Hamza Arif</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img3} fluid />
                <div className="team-desc">
                  <h4>CTO</h4>
                  <p>Muhammad Ahmad</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img4} fluid />
                <div className="team-desc">
                  <h4>Full Stack Developer</h4>
                  <p>Syed Asadullah</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img5} fluid />
                <div className="team-desc">
                  <h4>Business Development Head</h4>
                  <p>Summaiya </p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={8}
              md={8}
              lg={4}
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {" "}
              <div className="image-div">
                <Image src={img6} fluid />
                <div className="team-desc">
                  <h4>React Developer</h4>
                  <p>Zeeshan Naseeb</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img5} fluid />
                <div className="team-desc">
                  <h4>Designer Team Lead</h4>
                  <p>Rida Farooq</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img7} fluid />
                <div className="team-desc">
                  <h4>Backend Developer</h4>
                  <p>Muhammad Hamza</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurTeamPage;
